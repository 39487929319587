import { useEffect, useState, useRef } from "react";
import { handleRedirectUrl } from "../../components/main";
import Lottie from "lottie-react";
import animationData from "../../asset/96955.json";

const BASEURL = "https://staging.borga.money/borga/user/api/v1/kyc/mitek/redirect";

const SuccessPageComponent = () => {
  const [, setLoading] = useState(true);
  const [, setCode] = useState(1);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const url = window.location.href;
    const splitUrl = url.split("?");
    const queryParameter = splitUrl[1];

    console.log("original url from the backend :: ", url);
    console.log("the query params ", queryParameter);

    handleRedirectUrl(queryParameter, setCode, setLoading, BASEURL);
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "12vh",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ width: "300px", height: "250px" }}
          />
        </div>
        <div
          style={{
            fontSize: "1.9em",
            fontFamily: "Roboto, sans-serif",
            fontWeight: "bold",
          }}
        >
           Verification in progress!
        </div>
        <p style={{paddingLeft: "20px", paddingRight: "20px", fontFamily: "Roboto"}}> We will notify you as soon as we are done. Close this page to return to the app.</p>
      </div>
    </div>
  );
};

export default SuccessPageComponent;
