import { PageComponents } from "./routes";
import { Routes, Route } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        {PageComponents.map((data) => (
          <Route path={data.path} element={data.element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
